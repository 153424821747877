import { get } from "lodash";

/**
 * Klaviyo API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { klaviyoApi } from '../helpers/klaviyo'

    klaviyoApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
async function klaviyoApi(endpoint, method, body) {
    const options = {
        method: method ? method : 'GET'
    };
    
    if (body) {
        let bodyString = body;
        if (typeof body === 'object') {
        bodyString = JSON.stringify(body);
        }
        options.body = bodyString;
    }
    
    const parseJson = async response => {
        const text = await response.text();
        try {
        const json = JSON.parse(text);
        return json;
        } catch (err) {
        return text;
        }
    };
    
    return await fetch(
        `${process.env.GATSBY_LAMBDA_PATH}klaviyo?endpoint=${btoa(endpoint)}`,
        options
    ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

/**
 * Get all Klaviyo lists
 * 
 * Output list of mailing lists
 *  
 * @param   {String} email          The email to search on
 * 
 * @return  {array}                 List of mailing lists
 *
    import { getLists } from '../helpers/klaviyo'

    getLists();
 */
async function getLists() {
    return await klaviyoApi(`/v2/lists`);
}

/**
 * Get all Klaviyo lists by email
 * 
 * Output list of mailing lists an email is attached to
 *  
 * @param   {String} email          The email to search on
 * 
 * @return  {array}                 List of mailing lists
 *
    import { getListsByEmail } from '../helpers/klaviyo'

    getListsByEmail(email);
 */
async function getListsByEmail(email) {
    const lists = await getLists();
    if (lists.status === 200 && 'response' in lists && lists.response.length > 0) {
        return Promise.all(lists.response.map(async list => {
            let resSubscribed = await klaviyoApi(`/v2/list/${list.list_id}/subscribe?body=${JSON.stringify({emails:[email]})}`);
            return Promise.resolve({ list, subscribed: resSubscribed });
        }));
    }
}

/**
 * Subscribe form to list
 * 
 * Process subscription of an email to a defined list
 *  
 * @param   {Object} fields          Object of the fields being passed to Klaviyo
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { subscribeForm } from '../helpers/klaviyo'

    subscribeForm(listId, fields);
 */
async function subscribeForm(listId, fields) {
    return await klaviyoApi(`/v2/list/${listId}/subscribe`, 'POST', {
        profiles: [fields]
    });
}

/**
 * Subscribe email to list
 * 
 * Process subscription of an email to a defined list
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { subscribe } from '../helpers/klaviyo'

    subscribe(listId, email);
 */
async function subscribe(listId, email, wConsent = false) {
    return await klaviyoApi(`/v2/list/${listId}/subscribe`, 'POST', {
        profiles: [wConsent ? email : {email}]
    });
}

/**
 * Check if email exists in the list id
 * 
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { checkIfExisting } from '../helpers/klaviyo'

    checkIfExisting(listId, email);
 */
    async function checkIfExisting(listId, email) {
        return await klaviyoApi(`/v2/list/${listId}/get-members`, 'POST', {
            emails: [email]
        });
    }
    
/**
 * Unsubscribe email from list
 * 
 * Process subscription removal of an email from a defined list
 *  
 * @param   {String} email          The email to search on
 * @param   {Number} listId         The id of the list to subscribe to
 * 
 * @return  {boolean}               True or false of success
 *
    import { unsubscribe } from '../helpers/klaviyo'

    unsubscribe(listId, email);
 */
async function unsubscribe(listId, email) {
    return await klaviyoApi(`/v2/list/${listId}/subscribe`, 'DELETE', {
        emails: [email]
    });
}

/**
 * Identify website user
 * 
 * Identify logged in user
 * 
 * Special fields:
    $email: (this is automated for this script - pass as `email`)
    $first_name: string
    $last_name: string
    $phone_number: string; eg: "+13239169023"
    $city: string
    $region: string; state, or other region
    $country: string
    $zip: string
    $image: string; url to a photo of a person
    $consent: list of strings; eg: ['sms', 'email', 'web', 'directmail', 'mobile']
 * 
 * @param {*} props 
 * @returns 
 */

async function identify(props) {
    const fields = props;
    const email = props.email || false;

    if (!email) return false;

    delete fields.email;

    const object = {
        token: process.env.KLAVIYO_PUBLIC_KEY,
        properties: {
            $email: email,
            ...fields
        }
    }

    return await klaviyoApi(`/identify`, 'POST', object);
}

/**
 * Track user actions
 * 
 * Send events to Klaviyo on actions being taken  by the user around the site
 * 
 * Special fields:
    $email: string
    $first_name: string
    $last_name: string
    $phone_number: string; eg: "+13239169023"
    $city: string
    $region: string; state, or other region
    $country: string
    $zip: string
    $image: string; url to a photo of a person
    $consent: list of strings; eg: ['sms', 'email', 'web', 'directmail', 'mobile']
 * 
 * @param {*} props 
 * @returns 
 */

    async function track(event, props, userProps) {
        const fields = props;
        const userFields = userProps;
        const email = get(userFields, 'email', false);
    
        if (!event) return false;
    
        if (userFields && 'email' in userFields) {
            delete userFields.email;
        }
    
        const object = {
            token: process.env.KLAVIYO_PUBLIC_KEY,
            event,
            customer_properties: null,
            properties: null
        }

        if (userProps || email) {
            object.customer_properties = {
                $email: email,
                ...userFields
            }
        }

        if (fields) {
            object.properties = {
                ...fields
            }
        }

        // console.log("Klaviyo Track: ", event, object);
    
        return await klaviyoApi(`/track`, 'POST', object);
    }

    
/**
* Subscribe email to list
* 
* Process subscription of an email to a defined list
*  
* @param   {String} email          The email to search on
* @param   {Number} listId         The id of the list to subscribe to
* 
* @return  {boolean}               True or false of success
*
   import { subscribe } from '../helpers/klaviyo'

   subscribe(listId, email);
*/
    async function subscribeBackInStock(data) {
       return await klaviyoApi(`/onsite/components/back-in-stock/subscribe`, 'POST', {
           withPublicKey: true,
           formEncoded: true,
           ...data
       });
    }

export { 
    klaviyoApi,
    getLists,
    getListsByEmail,
    subscribeForm,
    subscribe,
    unsubscribe,
    checkIfExisting,
    identify,
    track,
    subscribeBackInStock
};
      