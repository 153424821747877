import { useState, useEffect } from 'react';
import { navigate } from 'gatsby'
import { track } from '../klaviyo';

/**
 * Track recently viewed products
 * 
 * Handles tracking of product views and stores product IDs in session for display on the site
 * 
 * @param   {Number} productId       The prodoct ID to track
 * 
    import { trackRV } from '../helpers/general'

    bcApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
function trackRV(productId) {
  const sessionKey = '__trackRV';
  const displayAmount = 10;

  if (typeof sessionStorage !== 'undefined') {
    const recentlyViewed = getStorage(sessionKey);
    let recentlyViewedArr = [];

    if (recentlyViewed !== null) {
      recentlyViewedArr = JSON.parse(recentlyViewed);
    }

    const existIndex = recentlyViewedArr.indexOf(productId);

    if (existIndex > -1) {
      recentlyViewedArr.splice(existIndex, 1);
    }

    recentlyViewedArr.unshift(productId);

    if (recentlyViewedArr.length > displayAmount) {
      recentlyViewedArr.pop();
    }

    setStorage(sessionKey, JSON.stringify(recentlyViewedArr), true);
  }
}

/**
 * Get local / session / cookie storage information
 * 
 * Handles retrieving browser storage information functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoStorage } from '../helpers/general'

    infoStorage('theKey')
 */
function infoStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return {storage: 'sessionStorage', value: sessionValue};
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return {storage: 'localStorage', value: localValue};
      }
    }
  } catch (e) {
    infoCookie(key);
  }

  return null;
}

/**
 * Get cookie storage information
 * 
 * Handles browser storage information functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { infoCookie } from '../helpers/general'

    infoCookie('theKey')
 */
function infoCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return {storage: 'cookie', value: c.substring(name.length, c.length)};
      }
    }
    return null;
  }
}

/**
 * Get local / session / cookie storage
 * 
 * Handles retrieving browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getStorage } from '../helpers/general'

    getStorage('theKey')
 */
function getStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        return sessionValue;
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        return localValue;
      }
    }
  } catch (e) {
    getCookie(key);
  }

  return null;
}

/**
 * Get cookie storage
 * 
 * Handles browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { getCookie } from '../helpers/general'

    getCookie('theKey')
 */
function getCookie(key) {
  if (typeof document !== 'undefined') {
    const name = `${key}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }
}

/**
 * Set local / session / cookie storage
 * 
 * Handles setting browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setStorage } from '../helpers/general'

    setStorage('theKey', 'theValue', true)
 */
function setStorage(key, value, expire) {
  try {
    // Use Local / Session storage
    if (typeof sessionStorage !== 'undefined' && expire) {
      sessionStorage.setItem(key, value);
    } else if (typeof localStorage !== 'undefined') {
      localStorage.setItem(key, value);
    }
  } catch (e) {
    // Use Cookies
    setCookie(key, value, expire);
  }
}

/**
 * Set cookie storage
 * 
 * Handles setting browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * @param   {String} value  The value to be stored
 * @param   {Boolean} expire Whether the data should expire or not. Defaults to false
 * 
    import { setCookie } from '../helpers/general'

    setCookie('theKey', 'theValue', true)
 */
function setCookie(key, value, expire) {
  if (typeof document !== 'undefined') {
    let exDays = 365;
    if (expire) {
      exDays = 1;
    }
    const d = new Date();
    d.setTime(d.getTime() + exDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=${value};${expires};path=/`;
  }
}

/**
 * Remove local / session / cookie storage
 * 
 * Handles removing browser storage functionality. Falls back to using cookies if required
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeStorage } from '../helpers/general'

    removeStorage('theKey')
 */
function removeStorage(key) {
  try {
    if (typeof sessionStorage !== 'undefined') {
      const sessionValue = sessionStorage.getItem(key);
      if (sessionValue) {
        sessionStorage.removeItem(key);
      }
    }

    if (typeof localStorage !== 'undefined') {
      const localValue = localStorage.getItem(key);
      if (localValue) {
        localStorage.removeItem(key);
      }
    }
  } catch (e) {
    // Use Cookies
    removeCookie(key);
  }
}

/**
 * Remove cookie storage
 * 
 * Handles removing browser storage functionality via cookies
 * 
 * @param   {String} key    The key used to set the value under
 * 
    import { removeCookie } from '../helpers/general'

    removeCookie('theKey')
 */
function removeCookie(key) {
  if (typeof document !== 'undefined') {
    const d = new Date();
    d.setTime(d.getTime() - 1 * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${key}=;${expires};path=/`;
  }
}

/**
 * Validate email format
 * 
 * Checks the provided email address and validates its format
 * 
 * @param   {String} email  The email address
 * 
    import { validateEmail } from '../helpers/general'

    validateEmail(email)
 */
function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * Get ordinal for date number
 * 
 * Given a date number, this will return the suffix for a date
 * 
 * @param   {Number} n  The date
 * 
    import { dateOrdinal } from '../helpers/general'

    dateOrdinal(25)
 */
function dateOrdinal(n) {
  return n > 3 && n < 21
    ? 'th'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 2
    ? 'nd'
    : n % 10 === 3
    ? 'rd'
    : 'th';
}

/**
 * Get value from querystring
 *
 * @param {string} key The key of the value you're looking to retrieve
 * @returns {string|false} the value or false if it doesn't exist
 */
 function getQuerystringValue(key) {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(key);

  return value || false;
}

/**
 * Get slug of the product from given uri
 *
 * @param {string} uri the url of the product
 * example: http://your-store-url.mybigcommerce.com/your-product/
 * @returns {string} slug of the product
 * example: /your-product/
 */
function getUriFromURL(urlStr = '') {
  const slugParts = urlStr
    .split('/')
    .filter(x => x);
  if (slugParts) {
    slugParts.splice(0, 2);
    return `/${slugParts.join('/')}/`;
  }
  return '';
}

/**
 * Handle links for international sites
 *
 * @param {string} relativeUrl the url for the link
 * example: /your-url/
 * @param {string} prependValue Supply prepended value when Gatsby doesn't handle automatically
 * example: /en-au
 * @returns {string} formatted URL for the environment
 * example: /country-language/your-url/
 */
function handleLink(relativeUrl = '', prependValue = '') {
  return `${prependValue}${relativeUrl}`;

  /* Replaced with pathPrefix gatsby functionality */
  
  // if (relativeUrl.startsWith('#')) {
  //   return relativeUrl;
  // }
  // else if (relativeUrl.startsWith('http')) {
  //   return relativeUrl;
  // }
  // return `${process.env.GATSBY_ENVIRONMENT_PATH}${relativeUrl}`;
}

/**
 * Calculate read time
 *
 * Pass content to be counted and return a minute value for the read time length
 *
 * @param   {string} content The content to be counted
 *
 * import { readTime } from '../helpers/general'
 *
 * readTime(content)
 */
function readTime(content) {
  const foundImages = (content.match(/<img/g) || []).length;
  const text = content.replace(/(<([^>]+)>)/gi, '');
  const foundWords = text.split(' ').length;

  let secs = (foundWords / 275) * 60;
  let addSecs = 12;
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs;
    addSecs--;
    if (addSecs < 3) addSecs = 3;
  }
  const minutes = Math.ceil(secs / 60);

  return minutes;
}

/**
 * Dissect title
 * 
 * Split out title to reveal the design type from the product name
 * 
 * @param   {string} productTitle   The product title
 * @param   {string} splitBy        The string to split by
 * 
 * import { dissectTitle } from '../helpers/general'
 * 
 * dissectTitle(productTitle, splitBy)
 * 
 * @returns {array} splitTitle
 */
function dissectTitle(productTitle, splitBy) {
    const titleParts = productTitle.split(splitBy);
    
    return titleParts;
}

/**
 * Window Dimensions
 *
 * Gets values from window object for the `useWindowDimensions` function
 *
 */
function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }

  return { width: 0, height: 0 };
}

/**
 * Window Dimensions
 * 
 * Provide on the fly window dimensions
 * 
    import { useWindowDimensions } from '../helpers/general'

    const { width } = useWindowDimensions()
 */
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }

    return;
  }, []);

  return windowDimensions;
}

/**
 * Is value numeric
 * 
 * Determine whether variable is a number
 * 
 * @param {*} str 
 *
  import { isNumeric } from '../helpers/general'

  isNumeric(value)
*/
function isNumeric(str) {
  if (['string', 'number'].indexOf(typeof str) === -1) return false // we only process strings and numbers!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
 * Format currency
 * 
 * Format provided value for the currency provided
 * 
    import { formatCurrency } from '../helpers/general'

    const {formattedPrice, currencySymbol} = formatCurrency(currency, amount, appendZero);
 */
function formatCurrency(currency, amount, appendZero = false) {
  let currencySymbol = '$';
  let displayAmount =  (typeof amount !== 'number' && parseFloat(amount?.replace('$', ''))) || amount;
  /* Set language display */
  const currencyToLang = {
    'AUD': 'en-AU',
    'GBP': 'en-GB',
    'EUR': 'en-EU',
    'USD': 'en-US',
  };
  const languageCode = currencyToLang[currency] || 'en-AU';
  if (languageCode === 'en-EU') {
    displayAmount = Math.round(displayAmount);
  }
  
  /* Format and return */
  // isolate currency
  const formatObject = new Intl.NumberFormat(languageCode, {
    style: 'currency',
    currency
  });
  let formattedPrice = formatObject.format(displayAmount);
  // console.log(currency, formattedPrice);
  if ('formatToParts' in formatObject) {
    const formattedPriceParts = formatObject.formatToParts(displayAmount);
    const currencyValue = formattedPriceParts.find(obj => obj.type === 'currency');
    const decimalValue = formattedPriceParts.find(obj => obj.type === 'fraction');
    currencySymbol = currencyValue.value.replace('A', ''); // Remove A on AUD currency return
    formattedPrice = formattedPrice.replace(currencyValue.value, '');
    if (decimalValue && decimalValue.value === '00' && !appendZero) {
      formattedPrice = formattedPrice.replace(`.${decimalValue.value}`, '');
    }
  } else {
    // new Intl.NumberFormat is not supported; fallback to dollar
    formattedPrice = amount;
  }

  return {
    formattedPrice,
    currencySymbol
  }
}

/**
 * useScript
 *
 * Provides a way to include a script on the frontend
 * 
    import { useScript } from '../helpers/general'

    useScript('https://path.to/script', 'functionOrObject', functionOnceLoaded);
 */
function useScript(url, functionOrObject, callback) {
  useEffect(() => {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const existing = functionOrObject in window;
      const loading = window.scriptsLoading && window.scriptsLoading.indexOf(functionOrObject) > -1;
      if (!existing && !loading) {
        if (!('scriptsLoading' in window)) {
          window.scriptsLoading = [];
        }
        window.scriptsLoading.push(functionOrObject);
        const script = document.createElement('script');

        script.src = url;
        script.async = true;

        document.body.appendChild(script);

        script.onload = () => { 
          if (callback) {
            callback();
          }
        };

        return () => {
          document.body.removeChild(script);
        }
      }

      if (existing && callback) {
        callback();
      }
    }
  }, [url, functionOrObject, callback])
}

function rewriteShippingOptionLabel(label, hideType = false) {
  let modLabel = label;
  if (hideType) {}
  return modLabel;
}

/**
 * dataLayerPush
 *
 * Enables a data push connection to a connected GTM container
 * 
    import { dataLayerPush } from '../helpers/general'

    dataLayerPush(eventName, {extraVariable: 'value'}, productData, linkToNavigate);

    Predefined event names:
      - SearchResults: sends list of products listed in the search result
        eg: dataLayerPush('SearchResults', null, productObject);

      - AllProductsOnPage: sends list of products listed on the current page (PLP, PDP, etc)
        eg: dataLayerPush('AllProducts On Page', null, productObject);

      - AddedToWishlist: sends product that was added to the wishlist
        eg: dataLayerPush('AddedToWishlist', null, productObject);

      - ProductClick: sets up enhanced ecomm tracking when a product is clicked to be viewed
        eg: dataLayerPush('ProductClick', null, productObject, path);

      - ProductView: sets up enhanced ecomm tracking when viewing a product page
        eg: dataLayerPush('ProductView', null, productObject);

      - AddedToCart: sets up enhanced ecomm tracking when adding a product (or products) to cart
        eg: dataLayerPush('AddedToCart', null, productObject);

      - RemovedFromCart: sets up enhanced ecomm tracking when removing a product (or products) to cart
        eg: dataLayerPush('RemovedFromCart', null, productObject);

      - Checkout: sends checkout data to track prior to payment
        eg: dataLayerPush('Checkout', null, checkoutObject, '/checkout/');
 */
async function dataLayerPush(eventName, extraData, productData, linkTo, forceExternal = false, customerRecord = null) {
  // Ensure window is available before moving forward
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // console.log('dataLayer Data', extraData, productData, linkTo);

    const object = {
      ...extraData,
      event: eventName
    }

    let klaviyoObject = false;
    let klaviyoEvent = null;

    let productsObject = false;

    const productObject = product => ({
      id: product.entityId || product.entity_id || product.productId || product.product_id || product.id || 0,
      name: product.title || product.name || '',
      price: (product.detailedPrices && product.detailedPrices.sale_price.tax_inclusive) || product.price || 0,
      currency: (extraData && extraData.currency) || 'AUD',
      // category: product.categories,
      quantity: product.quantity || 1,
    });

    if (productData) {
      const products = !Array.isArray(productData) ? [productData] : productData;
      productsObject = [...products.map(product => productObject(product))]
    }

    switch (eventName) {
      case 'SearchResults': 
      case 'AllProductsOnPage': 
        if (productsObject) {
          object.products = productsObject;

          if (eventName === 'SearchResults' && (extraData && 'term' in extraData)) {
            klaviyoEvent = "Searched Site";
            klaviyoObject = {
              SearchTerm: extraData.term,
              ReturnedResults: productsObject.length
            }
          } else if (eventName === 'AllProductsOnPage' && (extraData && 'category' in extraData)) {
            klaviyoEvent = "Viewed Category";
            klaviyoObject = {
              CategoryName: extraData.category,
              CategoryID: extraData.categoryId || 0,
              URL: extraData.url || '',
            }
          }
        }
        break;

      case 'ProductClick':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            click: {
              products: productsObject
            }
          }

          klaviyoEvent = "Clicked Product";
          klaviyoObject = productsObject[0]
        }
        break;

      case 'ProductView':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            detail: {
              products: productsObject
            }
          }

          klaviyoEvent = "Viewed Product";
          klaviyoObject = productsObject[0]
        }
        break;

      case 'AddedToCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            currencyCode: (extraData && extraData.currency) || 'AUD',
            add: { products: productsObject }
          }

          klaviyoEvent = "Added to Cart";
          klaviyoObject = {
            items: productsObject
          }
        }
        break;

      case 'RemovedFromCart':
        if (productsObject) {
          object.product = productsObject;
          object.ecommerce = {
            remove: {
              products: productsObject
            }
          }

          klaviyoEvent = "Removed from Cart";
          klaviyoObject = {
            items: productsObject
          }
        }
        break;

      case 'Checkout':
        if (productData) {
          // console.log("Checkout data", productData);
          const checkoutObject = productData;
          object.checkout = {
            totalAmount: checkoutObject.grand_total,
            tax: checkoutObject.tax_total,
            shipping: checkoutObject.shipping_cost_total_inc_tax,
            channelId: checkoutObject.cart.channel_id,
            products: []
          };
          // console.log(checkoutObject);
          const prodTypes = Object.keys(checkoutObject.cart.line_items);
          prodTypes.map((type) => {
            if (checkoutObject.cart.line_items[type].length > 0) {
              checkoutObject.cart.line_items[type].map((item) => {
                const itemId = `${item.productId}_${item.variantId}`;
                const itemName = `${item.name} (${item.sku})`;
                const product = productObject({id: itemId, title: itemName, price: item.salePrice, quantity: item.quantity});
                object.checkout.products.push(product);
                return true;
              });
            }
            return true;
          });

          klaviyoEvent = "Checkout Started";
          klaviyoObject = object.checkout
        }
        break;

      default:
        // do nothing
        break;
    }

    // Check if GTM is loaded
    if (linkTo) {
      if (typeof window.google_tag_manager !== 'undefined') {
        object.eventCallback = () => {
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else if (forceExternal === true) {
            document.location = linkTo.startsWith(process.env.GATSBY_ENVIRONMENT_PATH) ? linkTo : `${process.env.GATSBY_ENVIRONMENT_PATH}${linkTo}`;
          } else {
            navigate(linkTo.startsWith(process.env.GATSBY_ENVIRONMENT_PATH) ? linkTo.replace(process.env.GATSBY_ENVIRONMENT_PATH, '') : linkTo)
          }
        };
      } else {
          // Handler for when GTM is not present
          if (linkTo.startsWith('http')) {
            document.location = linkTo;
          } else if (forceExternal === true) {
            document.location = linkTo.startsWith(process.env.GATSBY_ENVIRONMENT_PATH) ? linkTo : `${process.env.GATSBY_ENVIRONMENT_PATH}${linkTo}`;
          } else {
            navigate(linkTo.startsWith(process.env.GATSBY_ENVIRONMENT_PATH) ? linkTo.replace(process.env.GATSBY_ENVIRONMENT_PATH, '') : linkTo)
          }
      }
    }

    await track(klaviyoEvent, klaviyoObject, customerRecord);

    // console.log('Pushing DataLayer: ', object);
    window.dataLayer.push(object);
  }
}

/**
 * Used to get all SKU variants of a product or if not multiple variants get the main SKU
 * @param {} product 
 * @returns 
 */
function getProductSku(product) {
  if (product.variants && product.variants.length > 1) {
      const sku = product.variants.map((prod) => prod.sku);
      return [product.sku, ...sku];
  }

  return product.sku
}

export {
  trackRV,
  infoStorage,
  infoCookie,
  getStorage,
  getCookie,
  setStorage,
  setCookie,
  removeStorage,
  removeCookie,
  validateEmail,
  dateOrdinal,
  getQuerystringValue,
  readTime,
  getUriFromURL,
  handleLink,
  dissectTitle,
  useWindowDimensions,
  useScript,
  isNumeric,
  formatCurrency,
  rewriteShippingOptionLabel,
  dataLayerPush,
  getProductSku
};
