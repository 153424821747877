import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { get } from 'lodash';

const ContentfulContext = createContext();

export const ContentfulProvider = ({ children }) => {
    const [headerMenu, setHeaderMenu] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const [secondaryFooterMenu, setSecondaryFooterMenu] = useState([]);
    const [socialLinks, setSocialLinks] = useState({});
    const [seoData, setSeoData] = useState({});
    const [translationData, setTranslationData] = useState({});
    const [productTranslationData, setProductTranslationData] = useState({});
    
    const contentfulData = useStaticQuery(graphql`
        query {
            siteConfig: allContentfulSiteConfig(filter: {name: {eq: "Site Config"}}) {
                nodes {
                  headerMenu {
                    menuItems {
                      title
                      url
                      linkTarget
                      linkLocation
                      content {
                        slug
                      }
                    }
                  }
                  footerMenu {
                    menuItems {
                      title
                      url
                      linkTarget
                      linkLocation
                      content {
                        slug
                      }
                    }
                  }
                  secondaryFooterMenu {
                    menuItems {
                      title
                      url
                      linkTarget
                      linkLocation
                      content {
                        slug
                      }
                    }
                  }
                  instagramLink
                  youTubeLink
                  facebookLink
                  defaultSocialShareImage {
                    file {
                      url
                    }
                  }
                  defaultMetaDescription {
                    defaultMetaDescription
                  }
                  streetAddress
                  suburb
                  postcode
                  country
                }
            }

            translations: allContentfulGlobalContent(filter: {name: {regex: "/^Translation:/"}}) {
              nodes {
                name
                title
                content {
                    raw
                }
              }
            }

            productTranslations: allContentfulProductTranslations {
              nodes {
                sku
                name
                description {
                  raw
                }
                warranty {
                  raw
                }
                blurb {
                  raw
                }
                videoHighlightLabel
                videoHighlightUrl
              }
            }
        }
    `);

    const fetchData = useCallback(() => {
        if (contentfulData && contentfulData.siteConfig.nodes.length > 0) {
            setHeaderMenu(get(contentfulData, 'siteConfig.nodes[0].headerMenu.menuItems', []));
            setFooterMenu(get(contentfulData, 'siteConfig.nodes[0].footerMenu.menuItems', []));
            setSecondaryFooterMenu(get(contentfulData, 'siteConfig.nodes[0].secondaryFooterMenu.menuItems', []));
            setSocialLinks({
                instagram: get(contentfulData, 'siteConfig.nodes[0].instagramLink', ''),
                facebook: get(contentfulData, 'siteConfig.nodes[0].facebookLink', ''),
                youTube: get(contentfulData, 'siteConfig.nodes[0].youTubeLink', ''),
            });
            setSeoData({
                ogImage: get(contentfulData, 'siteConfig.nodes[0].defaultSocialShareImage.file.url', ''),
                metaDescription: get(contentfulData, 'siteConfig.nodes[0].defaultMetaDescription.defaultMetaDescription', ''),
                streetAddress: get(contentfulData, 'siteConfig.nodes[0].streetAddress', ''),
                suburb: get(contentfulData, 'siteConfig.nodes[0].suburb', ''),
                postcode: get(contentfulData, 'siteConfig.nodes[0].postcode', ''),
                country: get(contentfulData, 'siteConfig.nodes[0].country', ''),
            })
        }

        if (contentfulData && contentfulData.translations.nodes.length > 0) {
          const translations = {};
          contentfulData.translations.nodes.map(translation => {
            const key = translation.name.replace('Translation: ', '');
            translations[key] = translation;
            return true;
          });
          setTranslationData(translations);
        }

        if (contentfulData && contentfulData.productTranslations.nodes.length > 0) {
          const productTranslations = {};
          contentfulData.productTranslations.nodes.map(translation => {
            const sku = translation.sku;
            productTranslations[sku] = translation;
            return true;
          });
          setProductTranslationData(productTranslations);
        }
    }, [contentfulData]);

    useEffect(() => fetchData(), [fetchData]);

    return (
        <ContentfulContext.Provider value={{
            headerMenu,
            footerMenu,
            secondaryFooterMenu,
            socialLinks,
            seoData,
            translationData,
            productTranslationData
        }}>{children}</ContentfulContext.Provider>
    );
};

export default ContentfulContext;