/**
 * Forcite API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The BC API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { forciteApi } from '../helpers/forcite'

    forciteApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */

async function forciteApi(endpoint, method, body) {
    // console.log('endpoint', endpoint)
    const options = {
        method: method ? method : 'GET'
    };

    if (body) {
        let bodyString = body;
        // console.log(body);
        if (typeof body === 'object') {
            bodyString = JSON.stringify(body)
        }

        options.body = bodyString
    }

    const parseJson = async response => {
        const text = await response.text()

        try {
            const json = JSON.parse(text)
            return json
        } catch (err) {
            return text
        }
    }

    if (typeof window !== 'undefined' && 'fetch' in window) {
        return await fetch(`${process.env.GATSBY_LAMBDA_PATH}forcite?endpoint=${btoa(endpoint)}`,
            options
        ).then(async res => ({
            response: await parseJson(res),
            status: res.status,
        }))
    } else return {response: {}, status: 404}
}

/**
 * Forcite BC Password
 * 
 * Handles the creation of a tokened password to be used for BC accounts based on forcite account data
 * 
 * @param   {Number} userId           The account user ID
 * 
 * @return  {string}                  The token generated
 *
    import { generateToken } from '../helpers/forcite'

    generateToken(123);
 */
async function generateToken(userId) {
    return await forciteApi('JWT', 'POST', {userId})
        .then(({response, status}) => {
            // console.log("Generate token response in forcite helper", response);
            if (status === 200 && response) {
                // console.log("Generate token in forcite helper", userId, response);
                return response;
            } else {
                // console.log("Generate token in forcite helper", userId, response);
                return response;
            }
        })
}

/**
 * Forcite Login
 * 
 * Handles the login process
 * 
 * @param   {Object} body           The body of the call.
 * 
 * @return  {Object}                {response, status}
 *
    import { login } from '../helpers/forcite'

    login({
        "Email": "joseph.azar@gmail.com",
        "Password": "qweQWE123!@#"
    });
 */
async function login(creds) {
    return await forciteApi('AuthenticateWebUser', 'POST', creds)
        .then(({response, status}) => {
            if (status === 200 && !('error' in response)) {
                // console.log("login response in forcite helper", response, creds);
                return response;
            } else {
                // console.log("failed login response in forcite helper", response, creds);
                return response;
            }
        })
}

/**
 * Forcite Signup
 * 
 * Handles the signup process
 * 
 * @param   {Object} body           The body of the call.
 * 
 * @return  {Object}                {response, status}
 *
    import { signup } from '../helpers/forcite'

    signup({
        "fname": "Website",
        "surname": "Test User",
        "birthday": "03-26-1980",
        "email": "joseph.azar@test.com",
        "avatar": null,
        "password": "123123123",
        "companyName": null,
        "country": null,
        "streetAddressLine1": null,
        "streetAddressLine2": null,
        "suburb": null,
        "state": null,
        "postcode": null,
        "phone": null
    });
 */
async function signup(fields) {
    const forciteFields = { ...fields };
    if ('authentication' in forciteFields) {
        forciteFields.password = forciteFields.authentication.new_password;
        delete forciteFields.authentication;
        delete forciteFields.confirm_password;
    }
    return await forciteApi('CreateWebUser', 'POST', forciteFields)
        .then(({response, status}) => {
            if (status === 200 && !('error' in response)) {
                // console.log("signup response in forcite helper", response, forciteFields);
                return response;
            } else {
                // console.log("failed signup response in forcite helper", response, forciteFields);
                return response;
            }
        })
}

/**
 * Forcite Get user details by user ID
 * 
 * Handles the ability to get the user record by the user ID
 * 
 * @param   {Object} body           The body of the call.
 * 
 * @return  {Object}                {response, status}
 *
    import { getByUserId } from '../helpers/forcite'

    getByUserId({
        "userId": 123
    });
 */
async function getByUserId(creds) {
    /*
    forciteApi('GetWebUserDetailsByUserID', 'POST', creds)
        .then(response => {
            console.log(response)
        })
    */
    // Assume successful
    // return true;
    // Assume invalid
    return false;
}

/**
 * Forcite Get user details
 * 
 * Handles the ability to get the user record
 * 
 * @param   {Object} body           The body of the call.
 * 
 * @return  {Object}                {response, status}
 *
    import { get } from '../helpers/forcite'

    get({
        "email": "joseph.azar@test.com"
    });
 */
    async function get(creds) {
        /*
        forciteApi('GetWebUserDetails', 'POST', creds)
            .then(response => {
                console.log(response)
            })
        */
        // Assume successful
        // return true;
        // Assume invalid
        return false;
    }

/**
 * Forcite Update user details
 * 
 * Handles the ability to update the user record
 * 
 * @param   {Object} body           The body of the call.
 * 
 * @return  {Object}                {response, status}
 *
    import { update } from '../helpers/forcite'

    update({
        "userId": 123,
        "fname": "Website",
        "surname": "Test User",
        "birthday": "03-26-1980",
        "email": "joseph.azar@test.com",
        "avatar": null,
        "password": "123123123",
        "companyName": null,
        "country": null,
        "streetAddressLine1": null,
        "streetAddressLine2": null,
        "suburb": null,
        "state": null,
        "postcode": null,
        "phone": null
    });
 */
async function update(fields) {
    return await forciteApi('UpdateWebUser', 'POST', fields)
        .then(({response, status}) => {
            if (status === 200 && !('error' in response)) {
                // console.log("signup response in forcite helper", response, forciteFields);
                return response;
            } else {
                // console.log("failed signup response in forcite helper", response, forciteFields);
                return response;
            }
        })
}

/**
 * Trigger open Zendesk widget
 * 
 * Ability to open the Zendesk widget on click. Helpful for any contact us links
 * 
 */
function openZendesk() {
    if (typeof window !== 'undefined') {
        window.zE(function() {
            window.zE.activate();
        });
    }
}

export { 
    forciteApi,
    generateToken,
    login,
    signup,
    get,
    getByUserId,
    update,
    openZendesk
}