import React, { createContext, useState } from 'react';

export const ReviewContext = createContext();

const initialState = {
    open: false,
  };

export const ReviewProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  const showReviewForm = (open = true) => {
    setState({...state, open: open});
  }

  return (
    <ReviewContext.Provider value={{
        state,
        setState,
        showReviewForm
    }}>{children}</ReviewContext.Provider>
  );
};

export default ReviewContext;