import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const PriceContext = createContext();

export const PriceProvider = ({ children, channelId, currency, version }) => {
  const [prices, setPrices] = useState({});
  const [pricesFetched, setPricesFetched] = useState(false);
  const [nonTaxProducts, setNonTaxProducts] = useState([]);

  const productPrices = useStaticQuery(graphql`
    query {
      allBigCommerceProductPricing {
        edges {
          node {
            product_id
            price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            retail_price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            sale_price {
              is_on_sale
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
            calculated_price {
              as_entered
              entered_inclusive
              tax_exclusive
              tax_inclusive
            }
          }
        }
      }

      allBigCommercePriceList {
        nodes {
          product_id
          variant_id
          currency
          price
          retail_price
          sale_price
          is_on_sale
          calculated_price
          price_list_id
        }
      }

      nonTaxProducts: allBigCommerceProducts(filter: {tax_class_id: {eq: 1}}) {
        nodes {
          id: bigcommerce_id
        }
      }
    }
  `);

  const fetchPrices = useCallback(() => {
    if (productPrices && productPrices.allBigCommerceProductPricing.edges.length > 0) {
      setPrices(
        productPrices.allBigCommerceProductPricing.edges &&
          productPrices.allBigCommerceProductPricing.edges.reduce((acc, item) => {
            const localPrice = productPrices.allBigCommercePriceList && productPrices.allBigCommercePriceList.nodes.filter(product => product.product_id === item.node.product_id);
            if (localPrice.length > 0) {
              acc[item.node.product_id] = {
                product_id: item.node.product_id,
                currency: localPrice[0].currency.toUpperCase(),
                price: {
                  as_entered: localPrice[0].price,
                  entered_inclusive: true,
                  tax_exclusive: null,
                  tax_inclusive: localPrice[0].price,
                },
                retail_price: null,
                sale_price: {
                  is_on_sale: localPrice[0].is_on_sale,
                  as_entered: localPrice[0].sale_price,
                  entered_inclusive: true,
                  tax_exclusive: null,
                  tax_inclusive: localPrice[0].sale_price,
                },
                calculated_price: {
                  as_entered: localPrice[0].calculated_price,
                  entered_inclusive: true,
                  tax_exclusive: null,
                  tax_inclusive: localPrice[0].calculated_price
                },
                variants: localPrice
              }
            } else {
              acc[item.node.product_id] = item.node;
              // console.log("Version", version);
              // console.log("Default currency:", currency)
              acc[item.node.product_id].currency = currency;
            }
            return acc;
          }, {})
      );
      setPricesFetched(true);
    } else {
      setPricesFetched(true);
    }
  }, [productPrices, currency]);

  const fetchNonTaxProducts = useCallback(() => {
    if (productPrices && productPrices.nonTaxProducts.nodes.length > 0) {
      setNonTaxProducts(productPrices.nonTaxProducts.nodes.map(p => p.id));
    }
  }, [productPrices]);

  useEffect(() => fetchPrices(), [fetchPrices]);
  useEffect(() => fetchNonTaxProducts(), [fetchNonTaxProducts]);

  return (
    <PriceContext.Provider value={{
      prices,
      pricesFetched,
      channelId,
      currency,
      nonTaxProducts
    }}>{children}</PriceContext.Provider>
  );
};

export default PriceContext;