import React, { createContext, useContext } from 'react';
// import { v4 } from 'uuid'

import AuthContext from './AuthProvider';
import WishlistContext from './WishlistProvider';

// import { getStorage, setStorage } from "../helpers/general";

const BindContext = createContext();

export const BindProvider = ({ children }) => {
    const auth = useContext(AuthContext);
    const authAddSupport = auth && auth.addSupport;
    const wishlist = useContext(WishlistContext);

    const init = () => {
        authAddSupport('wishlist', wishlist);

        // Set session token
        // if (!getStorage('__jammut')) {
        //     setStorage('__jammut', v4());
        // }
    }

    return (
        <BindContext.Provider value={{init}}>{children}</BindContext.Provider>
    );
};
    
export default BindContext;