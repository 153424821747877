import React from 'react';

import { GraphQLProvider } from './src/context/GraphQLProvider';
import { AuthProvider } from './src/context/AuthProvider';
import { WishlistProvider } from './src/context/WishlistProvider';
import { PriceProvider } from './src/context/PriceProvider';
import { CartProvider } from './src/context/CartProvider';
import { BindProvider } from './src/context/BindProvider';
import { ReviewProvider } from './src/context/ReviewProvider';
import { BlogListsProvider } from './src/context/BlogListsProvider';
import { ContentfulProvider } from './src/context/ContentfulProvider';

import ToastWrapper from './src/components/molecules/ToastWrapper/ToastWrapper';
import { setStorage, getStorage } from './src/helpers/general';

// require('dotenv').config()
export const wrapRootElement = ({ element }) => (
  <GraphQLProvider bcPath={process.env.GATSBY_BC_PATH} domain={process.env.GATSBY_SITE_PATH} channelId={process.env.GATSBY_BC_CHANNEL_ID}>
    <AuthProvider bcPath={process.env.GATSBY_BC_PATH} clientId={process.env.BC_CLIENT_ID} envPath={process.env.GATSBY_ENVIRONMENT_PATH}>
      <WishlistProvider>
        <PriceProvider channelId={process.env.GATSBY_BC_CHANNEL_ID} currency={process.env.GATSBY_BC_DEFAULT_CURRENCY} version={process.env.VERSION}>
          <CartProvider channelId={process.env.GATSBY_BC_CHANNEL_ID} currency={process.env.GATSBY_BC_DEFAULT_CURRENCY}>
            <BindProvider>
              <ReviewProvider>
                <ContentfulProvider>
                  <BlogListsProvider>
                    {element}
                    <ToastWrapper />
                  </BlogListsProvider>
                </ContentfulProvider>
              </ReviewProvider>
            </BindProvider>
          </CartProvider>
        </PriceProvider>
      </WishlistProvider>
    </AuthProvider>
  </GraphQLProvider>
);

export const onClientEntry = () => {
  const maintenanceKey = getStorage('_fmk');
  if (process.env.GATSBY_SITE_MAINTENANCE === "true" && window.location.pathname !== '/maintenance/' && window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') === -1 && maintenanceKey !== '92ef3ed2ca050dc623ed949ec04ccb56') {
    window.location = '/maintenance/';
  } else if (window.location.search.indexOf('92ef3ed2ca050dc623ed949ec04ccb56') > -1) {
    setStorage('_fmk', '92ef3ed2ca050dc623ed949ec04ccb56');
  }
}