import React, { createContext, useContext, useState, useEffect } from 'react';
import { get, values, sumBy } from 'lodash';
import AuthContext from './AuthProvider';
import { bcApi } from '../helpers/bigcommerce';
// import { success } from '../helpers/toast';
import { dataLayerPush, getStorage, setStorage } from '../helpers/general';

const LOCAL_STORE = {
  SHIPPING_METHOD: 'slm_shipping_option',
};

const CartContext = createContext();

const initialState = {
  cartFetched: false,
  cartLoading: false,
  cartError: false,
  cart: {
    currency: {
      code: 'USD'
    },
    cartAmount: 0,
    lineItems: {},
    numberItems: 0,
    redirectUrls: {}
  },
  shippingMethod: 'delivery',
  stockLoading: false,
  stockAvailability: []
};

const getItemsIdInCart = lineItems => {
  return values(lineItems)
    .reduce((acc, curr) => {
      return [
        ...acc,
        ...curr.map(ite => ({ item_id: ite.id, quantity: ite.quantity }))
      ];
    }, [])
    .filter(x => x);
};

export const CartProvider = ({ children, channelId, currency }) => {
  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const customer = auth && auth.state.object;
  const [state, setState] = useState(initialState);
  const [notifications, updateNotifications] = useState([]);

  const addNotification = (text, type = 'inform') => {
    updateNotifications([...notifications, { text, type, id: Date.now() }]);
  };

  const removeNotification = id => {
    updateNotifications(notifications.filter(ntfy => ntfy.id !== id));
  };

  const fetchCart = async () => {
    setState({ ...state, cartFetched: false });
    return await bcApi('carts')
      .then(({ response }) => {
        if (response) {
          if (Number(response.data.channel_id) !== Number(channelId)) {
            const cleared = clearCart(response.data.id, false);
            if (cleared) {
              addNotification(``, 'cartCleared');
              if (typeof window !== 'undefined') {
                setTimeout(() => {
                  window.location.reload();
                }, 7000)
              }
            }
          }
          return refreshCart(response);
        } else {
          setState({ ...state, cartFetched: true });
          return false;
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartFetched: false, cartError: error });
        return false;
      });
  };

  useEffect(() => {
    fetchCart()
    // eslint-disable-next-line
  }, []);

  const calculateNumberItems = lineItems => {
    const {
      physical_items = [],
      digital_items = [],
      custom_items = [],
      gift_certificates = []
    } = lineItems || {};
    const numberPhysical = sumBy(physical_items, ite => ite.quantity) || 0;
    const numberDigital = sumBy(digital_items, ite => ite.quantity) || 0;
    const numberCustom = sumBy(custom_items, ite => ite.quantity) || 0;
    const numberGift = sumBy(gift_certificates, ite => ite.quantity) || 0;
    return numberPhysical + numberDigital + numberCustom + numberGift;
  };

  const updateState = response => {
    // console.log("Update state", response);
    return new Promise(res => {
      const lineItems = response.data.line_items;
      const cartAmount = response.data.cart_amount;
      const baseAmount = response.data.base_amount;
      const currency = response.data.currency;
      const cartId = response.data.id;
      const coupons = response.data.coupons;

      // Fetch additional product data
      // const skus = response.data.line_items.physical_items.map(product => product.sku);
      const productIds = response.data.line_items.physical_items.map(product => product.product_id);
      bcApi(`catalog/products?include=variants&id:in=${productIds.join(',')}`).then(productData => {
        const productInfo = {};
        // const skus = [];
        productData.response.data.map(product => {
          productInfo[product.id] = product;
          // skus.push(product.sku);
          return true;
        });

        const newState = {
          ...state,
          cartFetched: true,
          cartLoading: false,
          updatingItem: false,
          coupons,
          cart: {
            cartId,
            currency,
            cartAmount,
            lineItems,
            productInfo,
            baseAmount,
            numberItems: calculateNumberItems(lineItems),
            redirectUrls: response.data.redirect_urls || state.cart.redirectUrls
          }
        };
        setState(newState);
        refreshCheckout(cartId, newState);
        res(true);
      });
    });
  }

  const refreshCart = response => {
    if (response.status === 204 || response.status === 404) {
      setState({ ...state, cartLoading: false });
    } else {
      return updateState(response);
    }
  };

  const clearCart = (manualCartId, reload = true) => {
    const cartId = manualCartId || ('cartId' in state.cart ? state.cart.cartId : null);
    if (cartId) {
      return bcApi(`carts/${cartId}`, 'DELETE').then(response => {
        // console.log(response);
        setState({ ...state, cartLoading: false });
        if (typeof window !== 'undefined' && reload) {
          window.location.reload();
        } else {
          return true;
        }
      })
    } else {
      return false;
    }
  }

  const refreshCheckout = (cartId, theState) => {
    bcApi(`checkouts/${cartId}`).then(response => {
      if (response.status === 200) {
        setState({...theState, checkout: response.response.data});
      }
    });
  };

  const initCheckout = async () => {
    const {response, status} = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      // console.log(response.data);
      setState({...state, cartFetched: true, checkout: response.data});
      return {...state, cartFetched: true, checkout: response.data};
    }
    return state;
  };

  const addToCart = (productId, variantId, optionSelections, retry, quantity = 1) => {
    return new Promise((res, rej) => {
      setState({ ...state, addingToCart: productId });
      const productLine = {
        quantity: parseInt(quantity, 10),
        product_id: parseInt(productId, 10)
      };
      // console.log("Option Selections");
      // console.log(optionSelections);
      if (optionSelections) {
        productLine.option_selections = optionSelections;
      } else {
        productLine.variant_id = parseInt(variantId, 10);
      }
      const bcApiBody = JSON.stringify({
        channel_id: channelId,
        currency: {code: currency},
        line_items: [
          productLine
        ]
      });
      bcApi('carts/items', 'POST', bcApiBody)
        .then(({ response, status }) => {
          if (status === 404 && !retry) {
            // re create a cart if cart was destroyed
            return bcApi('carts').then(() =>
              addToCart(productId, variantId, optionSelections, true, quantity)
            );
          }

          if ('data' in response) {
            const addedProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {
                if (item.product_id === productId) {
                  status < 300 && addNotification(item.name, 'cartAdd');
                  // success(`${item.name} added to cart`);
                  addedProduct.push({
                    id: `${item.product_id}_${item.variant_id}`,
                    title: item.name,
                    price: item.sale_price || item.price || 0,
                    quantity: item.quantity
                  })
                }
                return true;
              });
              return true;
            });
            let customerRecord = null;
            if (isLoggedIn) {
              customerRecord = {
                email: customer.email,
                $first_name: customer.first_name,
                $last_name: customer.last_name
              }
            }
            dataLayerPush('AddedToCart', {currency}, addedProduct, false, false, customerRecord);

            updateState(response).then(() => {
              res(true);
            });
          } else if (response.status > 300 && 'title' in response) {
            setState({ ...state, addingToCart: false, addToCartError: response });
            rej(response.title);
          }
        })
        .catch(error => {
          setState({ ...state, addingToCart: false, addToCartError: error });
          rej(error);
        });
    });
  };

  const addAllToCart = (items, retry) => {
    return new Promise((res, rej) => {
      const lineItems = items.filter(product => 'product_id' in product ? (product.product_id > 0) : (parseInt(product[0], 10) > 0)).map(product => {
        if ('product_id' in product) {
          const line = {
            quantity: 'quantity' in product ? parseInt(product.quantity, 10) : 1,
            product_id: parseInt(product.product_id, 10)
          }
          if (`option_selections` in product) {
            line.option_selections = product.option_selections;
          } else {
            line.variant_id = parseInt(product.variant_id, 10);
          }
          return line;
        } else {
          return {
            quantity: 1,
            product_id: parseInt(product[0], 10),
            variant_id: parseInt(product[1], 10)
          };
        }
      });

      if (lineItems.length > 0) {
        const bcApiBody = JSON.stringify({
          channel_id: channelId,
          currency: {code: currency},
          line_items: lineItems
        });
        bcApi('carts/items', 'POST', bcApiBody)
          .then(({ response, status }) => {
            if (status === 404 && !retry) {
              // re create a cart if cart was destroyed
              return bcApi('carts').then(() => addAllToCart(items, true));
            }

            if (status === 422) {
              rej(response);
              return;
            }
            
            status < 300 && addNotification(`${lineItems.length} products`, 'cartAdd');
            // success('Products added to cart');

            const addedProduct = [];
            const itemTypes = Object.keys(response.data.line_items);
            itemTypes.map(itemType => {
              response.data.line_items[itemType].map(item => {
                if (lineItems.find(product => item.product_id === product.product_id)) {
                  addedProduct.push({
                    id: `${item.product_id}_${item.variant_id}`,
                    title: item.name,
                    price: item.sale_price || item.price || 0,
                    quantity: item.quantity
                  })
                }
                return true;
              });
              return true;
            });
            let customerRecord = null;
            if (isLoggedIn) {
              customerRecord = {
                email: customer.email,
                $first_name: customer.first_name,
                $last_name: customer.last_name
              }
            }
            dataLayerPush('AddedToCart', {currency}, addedProduct, false, false, customerRecord);

            updateState(response).then(() => {
              res(true);
            });
          })
          .catch(error => {
            setState({ ...state, addingToCart: false, addToCartError: error });
            rej({ title: 'No products to add' });
          });
      }
    });
  };

  const updateItemInCart = (itemId, updatedItemData) => {
    const bcApiBody = JSON.stringify(updatedItemData);
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/updatecartlineitem
    bcApi(`carts/${state.cart.cartId}/items/${itemId}?include=redirect_urls,line_items.physical_items.options`, 'PUT', bcApiBody)
      .then(({ response }) => {
        refreshCart(response);
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const removeItemFromCart = itemId => {
    // @see https://developer.bigcommerce.com/api-reference/store-management/carts/cart-items/deletecartlineitem
    setState({ ...state, updatingItem: itemId });
    const removedProduct = [];
    const itemTypes = Object.keys(state.cart.lineItems);
    itemTypes.map(itemType => {
      state.cart.lineItems[itemType].map(item => {
        if (item.id === itemId) {
          removedProduct.push({
            id: `${item.product_id}_${item.variant_id}`,
            title: item.name,
            price: item.sale_price || item.price || 0,
            quantity: item.quantity
          });
        }
        return true;
      });
      return true;
    });

    bcApi(`carts/${state.cart.cartId}/items/${itemId}?include=redirect_urls,line_items.physical_items.options`, 'DELETE')
      .then(({ response, status }) => {
        // addNotification('Item removed successfully', 'inform');
        let customerRecord = null;
        if (isLoggedIn) {
          customerRecord = {
            email: customer.email,
            $first_name: customer.first_name,
            $last_name: customer.last_name
          }
        }
        dataLayerPush('RemovedFromCart', {currency}, removedProduct, false, false, customerRecord);

        if (status === 204) {
          setState({...initialState, cartFetched: true});
          return true;
          // return bcApi('carts').then(() => {
          //   /* do nothing */
          // });
        }
        // addNotification('Item removed successfully', 'inform);
        const stockAvailability = [...state.stockAvailability];
        if (stockAvailability.length > 0) {
          const availabilityData = [];
          response.data.line_items.physical_items.map(a => {
            const availableData = stockAvailability.find(s => s.sku === a.sku);
            if (availableData) {
              availabilityData.push(availableData);
            }
            return true;
          });
          updateStockAvailability(availabilityData);
          refreshCart(response);
        } else {
          refreshCart(response);
        }
      })
      .catch(error => {
        setState({ ...state, cartLoading: false, cartError: error });
      });
  };

  const updateCartItemQuantity = (item, action) => {
    let newQuantity;
    if (['minus', 'plus'].indexOf(action) > -1) {
      newQuantity = item.quantity + (action === 'minus' ? -1 : 1);
    } else {
      newQuantity = action;
    }
    
    // stale issue
    // setState({ ...state, updatingItem: item.id });
    setState((state) => {
      return {
        ...state,
        updatingItem: item.id
      }
    });

    if (newQuantity < 1) {
      return removeItemFromCart(item.id);
    }
    let productVariantReferences = null;

    if (typeof item.product_id !== 'undefined') {
      productVariantReferences = {
        product_id: item.product_id,
        variant_id: item.variant_id
      };
    }

    updateItemInCart(item.id, {
      line_item: {
        quantity: newQuantity,
        ...productVariantReferences
      }
    });
  };

  const addCoupons = async (coupon_code, fullResponse = false) => {
    return new Promise(async (res, rej) => {
      const endpoint = `checkouts/${state.cart.cartId}/coupons`;
      const reqBody = { coupon_code };
      try {
        const { response, status } = await bcApi(endpoint, 'POST', reqBody);
        const coupons = get(response, 'data.coupons');
        if (status === 200 && coupons) {
          setState({
            ...state,
            coupons
          });
          if (fullResponse) {
            res(response);
          } else {
            res(coupons);
          }
        } else {
          rej(response);
        }
      } catch (error) {
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const removeCoupons = async coupon_code => {
    return new Promise(async (res, rej) => {
      const endpoint = `checkouts/${state.cart.cartId}/coupons/${coupon_code}`;
      try {
        const { response, status } = await bcApi(endpoint, 'DELETE');
        const coupons = get(response, 'data.coupons');
        if (status === 200 && coupons) {
          setState({
            ...state,
            coupons
          });
          res(response);
        } else {
          rej(response);
        }
      } catch (error) {
        console.log(error);
        setState({ ...state, cartLoading: false, cartError: error });
        rej(error);
      }
    });
  };

  const addConsignments = async shippingAddress => {
    const { cartId, lineItems } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments`;
      const req_body = [
        {
          shipping_address: shippingAddress,
          line_items: getItemsIdInCart(lineItems)
        }
      ];

      try {
        const { response, status } = await bcApi(endpoint, 'POST', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: response.data.consignments
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateConsignments = async (shippingAddress, consignmentId) => {
    // PUT /checkouts/{checkoutId}/consignments/{consignmentId}
    const {
      cart: { cartId, lineItems }
    } = state;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/consignments/${consignmentId}`;
      const req_body = {
        shipping_address: shippingAddress,
        line_items: getItemsIdInCart(lineItems)
      };
      try {
        const { status } = await bcApi(endpoint, 'PUT', req_body);
        if (status === 200) {
          setState({
            ...state,
            consignments: []
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const removeConsignments = async (_state) => {
    const { response, status } = await bcApi(`checkouts/${state.cart.cartId}`);
    if (status === 200) {
      const _consignments = response.data.consignments || [];
      if (_consignments && typeof _consignments !== 'undefined' && _consignments.length) {
        const endpoint = `checkouts/${state.cart.cartId}/consignments/${_consignments[0].id}`;
        // console.log("Endpoint", endpoint);
        const resRemoved = await bcApi(endpoint, 'DELETE');
        // console.log("Consignment Removed", resRemoved);
        if (resRemoved.status === 200) {
          setState({ ..._state, consignments: resRemoved.response.data.consignments || [], checkout: resRemoved.response.data });
        }
      }
    }
  }

  const addGiftCertificates = async giftCertCode => {
    const { cartId } = state.cart;
    if (cartId) {
      const endpoint = `checkouts/${cartId}/gift-certificates`;
      // TODO: make this request to work
      try {
        const { response, status } = await bcApi(endpoint, 'POST', {
          giftCertificateCode: giftCertCode
        });
        console.log(status, response);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeShippingMethod = async (_method, _state = false) => {
    setStorage(LOCAL_STORE.SHIPPING_METHOD, _method);
    const _newState = { ...(_state ? _state : state), shippingMethod: _method };
    setState(_newState);

    if (_method === 'collect') {
      const result = await setPickupConsignment(_newState);
      if (result === 'forceDelivery') {
        await removeConsignments({ ..._newState, shippingMethod: 'delivery' });
        storeCheckoutData({ shipping: {} });
        return 'forceDelivery';
      } else {
        return true;
      }
    } else {
      await removeConsignments(_newState);
      storeCheckoutData({ shipping: {} });
      return true;
    }
  }

  // const changeSelectedStore = (_store) => {
  //   // store addresses
  //   setStorage(LOCAL_STORE.UID, _store.store_id);
  //   setStorage(LOCAL_STORE.NAME, _store.store_name);
  //   setStorage(LOCAL_STORE.ADDRESS, _store.store_address);
  //   setStorage(LOCAL_STORE.LOCATION, JSON.stringify(_store.store_location));

  //   // update state
  //   setState({ ...state, selectedStore: _store });
  // }

  const storeCheckoutData = (obj) => {
    const storeKey = '__jammcd';
    const existingJSON = getStorage(storeKey) || JSON.stringify({});
    const existing = JSON.parse(existingJSON);
    const newSet = JSON.stringify({...existing, ...obj});
    setStorage(storeKey, newSet);
  }

  const setPickupConsignment = async (_state) => {
    await removeConsignments(_state);

    const { cartId, lineItems } = _state.cart;
    const { selectedStore } = _state;
    const endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;

    if (lineItems.physical_items.length === 0) {
      // Cart can not be collected
      return 'forceDelivery';
    }

    if (`${selectedStore.store_name}`.trim() === '') {
      return false;
    }

    try {
      const resAddConsignment = await bcApi(endpoint, 'POST', [
        {
          shipping_address: {
            first_name: selectedStore.store_name,
            last_name: selectedStore.store_id,
            address1: selectedStore.store_location.address_1,
            city: selectedStore.store_location.suburb,
            phone: selectedStore.store_location.phone,
            email: selectedStore.store_location.email,
            postal_code: selectedStore.store_location.postcode,
            state_or_province: selectedStore.store_location.state,
            country_code: selectedStore.store_location.country,
          },
          line_items: getItemsIdInCart(lineItems)
        }
      ]);
      if (resAddConsignment.status === 200 && resAddConsignment.response.data.consignments.length > 0) {
        const _pickupOption = resAddConsignment.response.data.consignments[0].available_shipping_options.filter(a => ['click', 'collect', 'pickup'].filter(b => a.description.toLowerCase().includes(b)).length > 0);
        if (_pickupOption.length) {
          const endpoint = `checkouts/${state.cart.cartId}/consignments/${resAddConsignment.response.data.consignments[0].id}`;
          const resUpdatedCheckout = await bcApi(endpoint, 'PUT', { shipping_option_id: _pickupOption[0].id });
          if (resUpdatedCheckout.status === 200) {
            setState({
              ..._state,
              consignments: resAddConsignment.response.data.consignments,
              checkout: resUpdatedCheckout.response.data
            });
            storeCheckoutData({
              shipping: resUpdatedCheckout.response.data.consignments[0].shipping_address
            });
            return true;
          }
          return false;
        } else {
          // Preselected store - cart can not be collected
          return 'forceDelivery';
        }
      } else {
        // Preselected store - cart can not be collected
        return 'forceDelivery';
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  const loadingStock = loading => {
    setState({ ...state, stockLoading: loading});
  }

  const updateStockAvailability = async (_stocks) => {
    setState({ ...state, stockAvailability: _stocks });
  }

  return (
    <CartContext.Provider
      value={{
        state,
        fetchCart,
        addToCart,
        addAllToCart,
        addCoupons,
        removeCoupons,
        removeItemFromCart,
        updateCartItemQuantity,
        clearCart,
        notifications,
        addNotification,
        removeNotification,
        addConsignments,
        addGiftCertificates,
        updateConsignments,
        removeConsignments,
        changeShippingMethod,
        // changeSelectedStore,
        loadingStock,
        updateStockAvailability,
        initCheckout
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContext;
