import React, { createContext, useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const BlogListsContext = createContext();

export const BlogListsProvider = ({ children }) => {
    const [news, setNews] = useState([]);

    /*
      allContentfulBlogPostEvents {
        edges {
          node {
            id
            blogTitle
            blogFeaturedImage {
              file {
                url
                fileName
              }
            }
            blogContent {
              raw
              references {
                title
                file {
                  url
                  fileName
                }
              }
            }
            updatedAt(formatString: "MMMM DD, YYYY")
          }
        }
        totalCount
        pageInfo {
          currentPage
          hasNextPage
          pageCount
          totalCount
          perPage
        }
      }
    */
    
    const contentfulPosts = useStaticQuery(graphql`
        query {
          
          allContentfulBlogPostNews {
            edges {
              node {
                id
                blogTitle
                slug
                blogFeaturedImage {
                  file {
                    url
                    fileName
                  }
                }
                blogContent {
                  raw
                }
                updatedAt(formatString: "MMMM DD, YYYY")
              }
            }
            totalCount
            pageInfo {
              currentPage
              hasNextPage
              pageCount
              totalCount
              perPage
            }
          }
        }
    `);
    // console.log(contentfulPosts);

    const fetchPostsNews = useCallback(() => {
        if (contentfulPosts && contentfulPosts.allContentfulBlogPostNews.totalCount > 0) {
            setNews(
                contentfulPosts.allContentfulBlogPostNews.edges &&
                contentfulPosts.allContentfulBlogPostNews.edges.map((item) => {
                    return item;
                }, {})
            );
        }
    }, [contentfulPosts]);

    useEffect(() => {
        fetchPostsNews();
    // }, [fetchPostsNews]);
    }, [fetchPostsNews]);
    // console.log(events);
    // console.log(news);
    return (
        <BlogListsContext.Provider value={{
            news
        }}>{children}</BlogListsContext.Provider>
    );
};

export default BlogListsContext;