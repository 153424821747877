import React from 'react';

import * as styles from './ToastWrapper.module.css';

import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastWrapper = () => {
  
    return (
      <ToastContainer
        toastClassName={styles.toastContainer}
        autoClose={2000} 
        closeButton={false} 
        hideProgressBar={true} 
        transition={Slide}
        draggable={false}
      />
    )
}

export default ToastWrapper;