/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import GraphQLContext from './GraphQLProvider';
import AuthContext from './AuthProvider';
import { bcApi } from '../helpers/bigcommerce';
import { getStorage, setStorage } from '../helpers/general';

const WishlistContext = createContext();

const query = `
  query($productIds: [Int!] = [], $variantIds: [Int!] = [], $width: Int = 100) {
    site {
      products(first: 50, entityIds: $productIds) {
        edges {
          node {
            defaultImage {
              url(width: $width)
            }
            entityId
            name
            path
            variants(entityIds: $variantIds) {
              edges {
                node {
                  entityId
                }
              }
            }
            prices {
              price {
                value
              }
              retailPrice {
                value
              }
              salePrice {
                value
              }
            }
          }
        }
      }
    }
  }
`;

const initialState = {
  sessionWishlistIds: [],
  sessionWishlistIdCount: 0,
  sessionWishlistData: [],
  fetchRetries: 5
};

initialState.sessionWishlistIds =
  JSON.parse(getStorage('sessionWishlistIds')) || [];

initialState.sessionWishlistIdCount = initialState.sessionWishlistIds.length;

export const WishlistProvider = ({ children }) => {
  const graphql = useContext(GraphQLContext);
  const graphqlQuery = graphql && graphql.query;
  const auth = useContext(AuthContext);
  const isLoggedIn = auth && auth.state.isLoggedIn;
  const [init, setInit] = useState(false);
  const [sessionWishlistIds, setSessionWishlistIds] = useState(initialState.sessionWishlistIds);
  const [sessionWishlistIdCount, setSessionWishlistIdCount] = useState(initialState.sessionWishlistIdCount);
  const [sessionWishlistData, setSessionWishlistData] = useState(initialState.sessionWishlistData);
  const [fetchRetries, setFetchRetries] = useState(initialState.fetchRetries);

  const getSessionWishlist = width => {
    if (sessionWishlistIds.length > 0) {
      const productIds = [];
      const variantIds = [];
      sessionWishlistIds.map(ids => {
        productIds.push(ids[0]);
        if (ids[1] !== null) {
          variantIds.push(ids[1]);
        }

        return true;
      });

      graphqlQuery(query, { productIds, variantIds, width })
        .then(response => {
          if (response.data && response.data.site.products.edges.length > 0) {
            setSessionWishlistData(response.data.site.products.edges);
            setFetchRetries(initialState.fetchRetries);
          } else {
            return false;
          }
        })
        .catch(error => {
          // console.log("fetch list error:", error);
          if (fetchRetries > 0) {
            // console.log(state.fetchRetries);
            setFetchRetries(fetchRetries - 1);
            setTimeout(() => getSessionWishlist(), 1000);
          }
        });
    } else {
      setSessionWishlistData(initialState.sessionWishlistData);
      setFetchRetries(initialState.fetchRetries);
    }
  };

  const saveSessionWishlistIds = (data) => {
    setSessionWishlistIds(data);
    setSessionWishlistIdCount(data.length);
    setStorage('sessionWishlistIds', JSON.stringify(data));
  };

  useEffect(() => {
    getSessionWishlist();
  }, [sessionWishlistIds, sessionWishlistIdCount]);

  const loadSessionWishlist = () => {
    getSessionWishlist();
  };

  useEffect(() => {
    if (!init) {
      loadSessionWishlist()
      setInit(true);
    }
  }, [init]);

  const existsInWishlist = (productId, variantId) => {
    const currentWishlist = sessionWishlistIds;

    let existing = false;
    let existingIndex = null;

    currentWishlist.map((ids, idsIndex) => {
      if (ids[0] === productId && ids[1] === variantId) {
        existing = true;
        existingIndex = idsIndex;
      }

      return true;
    });

    return { active: existing, index: existingIndex };
  };

  const updateSessionWishlist = (productId, variantId) => {
    const currentWishlist = sessionWishlistIds;
    const existing = existsInWishlist(productId, variantId);

    if (existing.active) {
      currentWishlist.splice(existing.index, 1);
    } else {
      currentWishlist.push([productId, variantId]);
    }

    saveSessionWishlistIds(currentWishlist);
  };

  const clearSessionWishlist = () => {
    setSessionWishlistData([]);
    saveSessionWishlistIds([]);
  }

  const saveWishlist = (name, isLoggedInOverride, customerIdOverride) => {
    return new Promise(res => {
      // Check if user is logged in
      let logged = isLoggedIn;
      if (typeof isLoggedInOverride !== 'undefined') {
        logged = isLoggedInOverride;
      }
      let customerId = auth.state.customerId;
      if (typeof customerIdOverride !== 'undefined') {
        customerId = customerIdOverride;
      }

      if (!logged) {
        // TODO: Set session to resume process after authentication
        setStorage(
          '_afterAuth',
          JSON.stringify({ action: 'saveWishlist', name }),
          true
        );
        console.log('User not logged in :(');
        navigate(`/login/`);
        res(true);
        return;
      }

      // Enter products into new wishlist
      const items = sessionWishlistIds.map(product => {
        return {
          product_id: parseInt(product[0], 10),
          variant_id: parseInt(product[1], 10)
        };
      });

      const wishlistsBody = JSON.stringify({
        customer_id: customerId,
        is_public: true,
        name,
        items
      });
      bcApi('wishlists', 'POST', wishlistsBody).then(response => {
        // Running manually as saveSessionWishlistIds isn't updating the session for some reason
        setSessionWishlistData(initialState.sessionWishlistData);
        setSessionWishlistIds(initialState.sessionWishlistIds);
        setStorage('sessionWishlistIds', JSON.stringify([]));
        console.log('Redirecting to wishlists');
        navigate('/account/wishlists/');
        res(response);
        return;
      });
    });
  };

  const getAccountWishlists = () => {
    const customerId = auth.state.customerId;
    return new Promise(res => {
      bcApi(`wishlists?customer_id=${customerId}&limit=250`).then(({ response }) => {
        res(response);
      });
    });
  };

  const updateAccountWishlist = (wishlistId, name, items) => {
    const bcApiBody = {
      customer_id: auth.state.customerId
    };
    if (name) bcApiBody.name = name;
    if (items) bcApiBody.items = items;

    return new Promise(res => {
      bcApi(`wishlists/${wishlistId}`, 'PUT', bcApiBody).then(
        ({ response }) => {
          res(response);
        }
      );
    });
  };

  const getWishlistItems = (items, width) => {
    const productIds = [];
    const variantIds = [];
    items.map(ids => {
      if (ids.product_id) {
        productIds.push(ids.product_id);
      }
      if (ids.variant_id) {
        variantIds.push(ids.variant_id);
      }
      return true;
    });
    return graphqlQuery(query, { productIds, variantIds, width })
      .then(response => {
        // console.log("Product data:", response);
        if (response.data && response.data.site.products.edges.length > 0) {
          return response.data.site.products.edges;
        } else {
          return [];
        }
      })
      .catch(error => {
        // console.log("fetch list error:", error);
        return error;
      });
  };

  // const addToAccountWishlist = () => {

  // }

  const removeWishlist = wishlistId => {
    return new Promise(res => {
      bcApi(`wishlists/${wishlistId}`, 'DELETE').then(response => {
        res(response);
      });
    });
  };

  const removeFromAccountWishlist = (
    wishlistId,
    wishlistItems,
    wishlistItemId,
    productId,
    variantId
  ) => {
    return new Promise((res, rej) => {
      if (!wishlistId) rej('Missing wishlist ID');

      if (wishlistItemId) {
        // Use wishlist item ID to remove
        bcApi(`wishlists/${wishlistId}/items/${wishlistItemId}`, 'DELETE').then(
          ({ response }) => {
            res(response);
          }
        );
      } else if (productId && variantId) {
        // Use the product ID and variant ID to find the wishlist item in the wishlist
        let _wishlistItems = wishlistItems;
        const promises = [];
        if (!wishlistItems) {
          promises.push(
            new Promise((wlres, wlrej) => {
              bcApi(`wishlists/${wishlistId}`).then(({ response }) => {
                if (response.data) {
                  _wishlistItems = response.data.items;
                  wlres(true);
                } else {
                  wlrej(false);
                }
              });
            })
          );
        }

        let _wishlistItemId = -1;
        Promise.all(promises).then(() => {
          if (_wishlistItems) {
            let deleteIndex = -1;
            _wishlistItems.map((item, itemIndex) => {
              if (
                item.product_id === productId &&
                item.variant_id === variantId
              ) {
                _wishlistItemId = item.id;
                deleteIndex = itemIndex;
              }

              return true;
            });

            if (deleteIndex > -1) {
              _wishlistItems.splice(deleteIndex, 1);
            }
          }

          if (_wishlistItemId > 0) {
            bcApi(
              `wishlists/${wishlistId}/items/${_wishlistItemId}`,
              'DELETE'
            ).then(({ response, status }) => {
              if (status < 300) {
                res(_wishlistItems);
              } else rej(status);
            });
          }
        });
      }
    });
  };

  return (
    <WishlistContext.Provider
      value={{
        sessionWishlistIds,
        sessionWishlistData,
        updateSessionWishlist,
        clearSessionWishlist,
        existsInWishlist,
        saveWishlist,
        getAccountWishlists,
        updateAccountWishlist,
        getWishlistItems,
        removeFromAccountWishlist,
        removeWishlist
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export default WishlistContext;
