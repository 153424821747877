import React, { createContext, useContext, useState, useEffect } from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
import GraphQLContext from './GraphQLProvider';
import { bcApi } from '../helpers/bigcommerce'
import { identify, track } from '../helpers/klaviyo'
import { generateToken, signup as forciteSignup, login as forciteLogin } from '../helpers/forcite'
import { getStorage, removeStorage, setStorage, infoStorage } from '../helpers/general'

const AuthContext = createContext();

const initialState = {
    userChecked: false,
    isLoggedIn: false,
    customerId: 0,
    email: '',
    support: {},
  };

export const AuthProvider = ({ children, bcPath, clientId, envPath }) => {
  const graphqlCxt = useContext(GraphQLContext);
  const graphqlQuery = graphqlCxt && graphqlCxt.query
  const [state, setState] = useState(initialState);

  /**
   * This data keeps changing if a customer group has discounts assigned or not. 
   * If some are assigned, there is an additional level with `_1` under discount_rules.
   * GraphQL is doesn't expect schemas to change dynamically like that which is a problem here.
   */
//    const customerGroupData = useStaticQuery(graphql`
//    query {
//      allBigCommerceCustomerGroups {
//        edges {
//          node {
//            name
//            id: bigcommerce_id
//            discount_rules {
//              amount
//              method
//              type
//            }
//            is_default
//            is_group_for_guests
//          }
//        }
//      }
//    }
//  `)
 const customerGroups = {};
//  customerGroupData?.allBigCommerceCustomerGroups.edges.map(group => {
//    customerGroups[group.node.id] = group.node;

//    return true;
//  });

  const addSupport = (key, value) => {
    const support = state.support;
    if (!(key in support)) {
      support[key] = value;
      setState({...state, support});
    }
  }

  const checkLoggedIn = () => {
    const sessionData = infoStorage('_loggedIn');
    if (sessionData) {
        // Check timestamp, force login after a day
        const userObject = JSON.parse(atob(sessionData.value));
        if (userObject.envPath === envPath) {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          if ('timestamp' in userObject) {
              // console.log(date.getTime(), userObject.timestamp)
              if (date.getTime() < userObject.timestamp) {
                  // console.log("Fetching user");
                  userObject.timestamp = Date.now();
                  setStorage('_loggedIn', btoa(JSON.stringify(userObject)), sessionData.storage === 'sessionStorage' ? true : false);
                  fetchLogin(userObject.customerId)
              } else {clearStorage();setState({...state, userChecked: true})}
          } else {clearStorage();setState({...state, userChecked: true})}
        } else {clearStorage();setState({...state, userChecked: true})}
    } else {clearStorage();setState({...state, userChecked: true, onFetchLogin: false})}
  }

  // eslint-disable-next-line
  useEffect(() => checkLoggedIn(), []);

  const getCustomerGroup = (customerGroupId) => {
    if (customerGroups && customerGroupId in customerGroups) {
      return customerGroups[customerGroupId].name;
    }

    return 'guest';
  } 

  const fetchLogin = async (id) => {
    setState({...state, onFetchLogin: true})
    return new Promise((resolve) => {
      bcApi(`customers?id:in=${id}&include=formfields,addresses`).then(async ({response}) => {
        setState({
          ...state,
          userChecked: true,
          customerId: response.data[0].id,
          email: response.data[0].email,
          customerGroup: getCustomerGroup(response.data[0].customer_group_id),
          object: response.data[0],
          isLoggedIn: true,
          onFetchLogin: false
        });
        await identify({
          email: response.data[0].email,
          bigCommerceId: response.data[0].id,
          $first_name: response.data[0].first_name,
          $last_name: response.data[0].last_name,
          $phone_number: response.data[0].phone,
          $city: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].city : '',
          $region: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].state_or_province : '',
          $country: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].country : '',
          $zip: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].postcode : '',
        })
        resolve(response)
      })
      .catch(error => {
      //   console.log('Error:', error)
        setState({...initialState, userChecked: true, onFetchLogin: false})
        resolve(error)
      });
    })
  }

  const refreshData = () => {
    if (state.isLoggedIn) {
      fetchLogin(state.customerId);
    }
  }

  const afterAuth = async (customerId) => {
    const afterAuthCalls = getStorage('_afterAuth');
    if (afterAuthCalls) {
      const afterAuthCallsObject = JSON.parse(afterAuthCalls);
      if (afterAuthCallsObject.action === 'saveWishlist') {
        // console.log("_afterAuth should be removed");
        removeStorage('_afterAuth')
        const actionResult = await state.support.wishlist.saveWishlist(afterAuthCallsObject.name, true, customerId)
        return actionResult
      }
    }

    return false
  }

  const logout = () => {
      // TODO: Update to work with more secure process
      setState(initialState)
      clearStorage();

      graphqlQuery(`
        mutation Logout {
          logout {
            result
          }
        }
      `).then(response => {
        if (typeof window !== 'undefined') {
          window.location = '/';
        }
      });
  }

  const clearStorage = () => {
    removeStorage('_loggedIn')
    removeStorage('_isWholesale')
    removeStorage('_isPending')
  }

  /***** SECOND AUTH ATTEMPT - Works validating user with GraphQL Mutation however session retaining is hacked together insecure *****/
  const checkPassword = (email, password) => {
    return new Promise((res, rej) => {
      forciteLogin({Email: email, Password: password}).then(async forciteAuthResp => {
        if (forciteAuthResp && !('error' in forciteAuthResp)) {
          // console.log("Forcite login in auth provider", forciteAuthResp);
          const token = await generateToken(forciteAuthResp.userId);
          // console.log("Forcite token in auth provider", token);
          graphqlQuery(`
            mutation login($email: String!, $password: String!) {
              login(email: $email, password: $password) {
                customer {
                  entityId
                }
              }
            }
          `, { email, password: token }).then(response => res({response, forciteAuthResp}));
        } else {
          // console.log('Error in Forcite Auth:', forciteAuthResp.error, forciteAuthResp.data.message)
          rej("Incorrect username and/or password");
        }
      }).catch(error => {
        console.log('Error in Forcite Auth:', error)
        rej("Incorrect username and/or password")
      });
    })
  }

  const login = (email, password, remember) => {
    return new Promise((res, rej) => {
      checkPassword(email, password).then(responseData => {
        const {response, forciteAuthResp} = responseData;
        if (response.data && response.data.login.customer) {
          // Confirmed successful login
          // TODO: Replace temp work around with more secure method
          // If remember logged in, then no expire, else expire login session
          bcApi(`customers`, 'PUT', [{
            id: response.data.login.customer.entityId,
            first_name: forciteAuthResp.fname,
            last_name: forciteAuthResp.surname,
          }]).then(() => {
            let expire = remember ? false : true;
            res(fetchAndSetCustomer(email,expire));
          });
        } else {
          // Check if we need to create the account or whether we have an issue with the password not matching the generated token
          bcApi(`customers?email:in=${email}&include=formfields`).then(({response}) => {
            if (response.data && response.data.length === 0) {
              // No account found. What if they have changed their email via the app?
              // Search for the user ID
              bcApi(`customers/form-field-values?field_name=Forcite User ID`).then(async ({response}) => {
                if (response.data && response.data.length > 0) {
                  const foundId = response.data.find(userIdField => forciteAuthResp.userId === userIdField.value);
                  if (!foundId) {
                    // user found, fetch required info to create account
                    const token = await generateToken(forciteAuthResp.userId);
                    const fieldsUpdated = {
                      first_name: forciteAuthResp.fname,
                      last_name: forciteAuthResp.surname,
                      email: forciteAuthResp.email,
                      authentication: {
                        new_password: token
                      }
                    };

                    bcRego(fieldsUpdated, [], forciteAuthResp)
                      .then(resp => res(resp))
                      .catch(reject => rej(reject));
                  } else {
                    // Account found, but with a different email - update BC customer and attempt the login again
                    bcApi(`customers`, 'PUT', [{
                      id: foundId.customer_id,
                      email,
                      first_name: forciteAuthResp.fname,
                      last_name: forciteAuthResp.surname,
                    }]).then(() => {
                      // Reattempt login
                      login(email, password, remember);
                    })
                  }
                } else {
                  // First time login to the site, create account based on Source of truth account
                  const token = await generateToken(forciteAuthResp.userId);
                  const fieldsUpdated = {
                    first_name: forciteAuthResp.fname,
                    last_name: forciteAuthResp.surname,
                    email: forciteAuthResp.email,
                    authentication: {
                      new_password: token
                    }
                  };

                  bcRego(fieldsUpdated, [], forciteAuthResp)
                    .then(resp => res(resp))
                    .catch(reject => rej(reject));
                }
              });
            } else if (response.data) {
              // Account found, meaning there was an issue with the token in the previous step (Could be a local only issue)
              // Check if Forcite User ID is on found account
              const forciteUserId = response.data[0].form_fields.find(field => field.name === "Forcite User ID" && field.value === forciteAuthResp.userId);
              if (forciteUserId) {
                let expire = remember ? false : true;
                res(fetchAndSetCustomer(email,expire));
              }
            } else {
              // Error in the call
              // TODO: Provide a more substantial error
              rej("Error occurred")
            }
          });
        }
      }).catch(error => {
        rej(error);
      });
    });
  }

  const fetchAndSetCustomer = (email, expire) => {
    return new Promise((res, rej) => {
      bcApi(`customers?email:in=${email}&include=formfields,addresses`).then(async ({response}) => {
        // console.log('Response:', response)
        if (response.data) {
          const customerId = response.data[0].id;
          setState({...state, customerId, email: response.data[0].email, object: response.data[0]})
          setStorage('_loggedIn', btoa(JSON.stringify({customerId, timestamp: Date.now(), userChecked: true, envPath})), expire)
          await track('Account Login', null, {
            email: response.data[0].email,
            bigCommerceId: response.data[0].id,
            $first_name: response.data[0].first_name,
            $last_name: response.data[0].last_name,
            $phone_number: response.data[0].phone,
            $city: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].city : '',
            $region: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].state_or_province : '',
            $country: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].country : '',
            $zip: response.data[0].addresses.length > 0 ? response.data[0].addresses[0].postcode : '',
          });
          const afterAuthAction = await afterAuth(customerId);
          // console.log("After login triggered", afterAuthAction)

          if (typeof window !== 'undefined' && typeof document !== 'undefined' && !afterAuthAction) {
            // console.log("Redirecting normally");
            const browserLastPage = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
            const userLastPage = getStorage('lastPage');
            const forcedPage = getStorage('forcedPage');
            const returnUrl = forcedPage || userLastPage || browserLastPage;
            removeStorage('forcedPage');
            window.location = returnUrl;
            // bcLoginUrl can't be used as the redirect has to be relative to the BC store URL.
            // bcLoginUrl(customerId, returnUrl).then(loginUrl => {
            //   console.log("Logged in promise", loginUrl);

            //   window.location = redirectUrl
            // });
          }
          res("Success");
        } else {
          setState({...initialState, userChecked: true})
          // TODO: throw error feedback to page
          rej("Failed to fetch");
        }
      })
      .catch(error => {
        // console.log('Error:', error)
        setState({...initialState, userChecked: true})
        // TODO: throw error feedback to page
        rej("Failed to fetch");
      });
    });
  }

  // const getCurrentCustomer = () => {
  //   fetch(`${bcPath}/customer/current.jwt?app_client_id=${clientId}`,
  //     {
  //       method: 'GET',
  //     }
  //   )
  //     .then(res => res.json())
  //     .then(response => {
  //       console.log('Response:', response)
  //     });
  // }
  /***** END SECOND AUTH ATTEMPT *****/

  /***** FIRST AUTH ATTEMPT - Works but uses deprecated BC Endpoint, also insecure *****/
  //    const login = (email, password) => {
  //    bcApi(`customers&email:in=${email}`).then(({response}) => {
  //       // console.log('Response:', response)
  //       setState({...state, customerId: response.data[0].id, email: response.data[0].email, object: response.data[0]})
  //       validate(response.data[0].id, password)
  //     })
  //     .catch(error => {
  //       // console.log('Error:', error)
  //       setState({...initialState, userChecked: true})
  //       // TODO: throw error feedback to page
  //     });
  // };

  // const validate = (customerId, password) => {
  //    const bcApiBody = JSON.stringify({ password });
  //    bcApi(`loginValidate&customerId=${customerId}`, 'POST', bcApiBody).then(({response}) => {
  //       if (response.success) {
  //           setState({...state, isLoggedIn: response.success});
  //           // TODO: Setup secured authentication storage
  //           if (typeof sessionStorage !== 'undefined') {
  //               sessionStorage.setItem('_loggedIn', btoa(JSON.stringify({customerId, timestamp: Date.now(), userChecked: true, envPath})))
  //           }
  //           if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  //               window.location = document.referrer;
  //           }
  //       } else {
  //         setState({...initialState, userChecked: true})
  //         // TODO: throw error feedback to page
  //       }
  //     })
  //     .catch(error => {
  //       // console.log('Error:', error)
  //       setState({...initialState, userChecked: true})
  //       // TODO: throw error feedback to page
  //     });
  // };
  /***** END FIRST AUTH ATTEMPT *****/

  // FORGOT PASSWORD PROCESS CARRIED OUT EXTERNALLY - https://app.forcitehelmets.com/public/forgotpassword
  // const forgotPassword = (email) => {
  //   return new Promise((res) => {
  //     fetch(`${bcPath}/login.php?action=send_password_email`, {
  //       body: `email=${email}`,
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //       method: "post",
  //     }).then(result => {
  //       // console.log(result);
  //       res(true)
  //     }).catch(error => {
  //       // Endpoint returns failed however the process is actually successful. As such, we can't effectively
  //       // console.log(error);
  //       res(true)
  //     });
  //   });
  // }

  // PASSWORD CHANGES MADE EXTERNALLY - https://app.forcitehelmets.com/public/forgotpassword
  // const changePassword = (fields, customerId, token) => {
  //   const bcApiBody = JSON.stringify([fields]);
  //   fetch(`${bcPath}/login.php?action=save_new_password&c=${customerId}&t=${token}`, {
  //     body: bcApiBody,
  //     credentials: "include",
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //     },
  //     method: "post",
  //   }).then(result => {
  //     console.log(result);
  //   });
  // }

  const signup = (fields) => {
    return new Promise((res, rej) => {
      /* Prep and validate fields */
      const fieldsUpdated = fields;
      if ('firstName' in fieldsUpdated) {
        fieldsUpdated.first_name = fields.firstName;
      }
      if ('lastName' in fieldsUpdated) {
        fieldsUpdated.last_name = fields.lastName;
      }
      if ('address1' in fieldsUpdated) {
        fieldsUpdated.addresses = [{
          first_name: fields.firstName,
          last_name: fields.lastName,
          company: fields.company,
          address1: fields.address1,
          address2: fields.address2,
          city: fields.city,
          state_or_province: fields.stateOrProvince,
          postal_code: fields.postalCode,
          country_code: fields.countryCode,
          phone: fields.phone,
          address_type: 'commercial'
        }]
      }
      
      const customFields = [];
      Object.keys(fieldsUpdated).map(fieldKey => {
        if (fieldKey.startsWith('custom_')) {
          const key = fieldKey.replace('custom_', '');
          customFields.push({
            name: key,
            value: fieldsUpdated[fieldKey],
          });
          delete fieldsUpdated[fieldKey];
        }

        return true;
      });

      /* Register on Forcite app first */
      forciteSignup(fieldsUpdated).then(forciteRegResp => {
        // Successfully registered with Forcite - Process auth to retrieve generated data from Forcite about the customer
        // console.log("forcite signup in auth provider", forciteRegResp);
        if (forciteRegResp && !('error' in forciteRegResp)) {
          // res('We send and account verification to your email, please check to activate your account.')
          
          forciteLogin({Email: fieldsUpdated.email, Password: fieldsUpdated.authentication.new_password}).then(forciteAuthResp => {
            // Successfully confirm auth from Forcite - create account on BC
            if (forciteAuthResp && !('error' in forciteAuthResp)) {
              // console.log("forcite login in auth provider", fieldsUpdated, customFields, forciteAuthResp);
              bcRego(fieldsUpdated, customFields, forciteAuthResp)
                .then(async resp => {
                  // console.log("Response from bcRego", resp);
                  await track('Created Account', null, {
                    email: fieldsUpdated.email,
                    bigCommerceId: resp.id,
                    $first_name: fieldsUpdated.first_name,
                    $last_name: fieldsUpdated.last_name,
                    $phone_number: fieldsUpdated.phone || '',
                    $city: fieldsUpdated.addresses.length > 0 ? fieldsUpdated.addresses[0].city : '',
                    $region: fieldsUpdated.addresses.length > 0 ? fieldsUpdated.addresses[0].state_or_province : '',
                    $country: fieldsUpdated.addresses.length > 0 ? fieldsUpdated.addresses[0].country : '',
                    $zip: fieldsUpdated.addresses.length > 0 ? fieldsUpdated.addresses[0].postcode : '',
                  });
                  res("Success")
                })
                .catch(reject => {
                  // console.log("Error from bcRego", reject);
                  rej(false);
                });
            } else {
              // This is a problem because the account was just created with the same details and returned successfully to get here
              // console.log('Error in Forcite Auth:', forciteAuthResp.error, forciteAuthResp.data.message)
              setState({...initialState, userChecked: true});
              res('We send you an account verification, please check your email to activate your account.')
              // rej(forciteAuthResp.data.message);
            }
          }).catch(error => {
            // console.log('Error in Forcite Auth:', error)
            setState({...initialState, userChecked: true});
            rej(error);
          });
        } else {
          // console.log('Error in Forcite Rego:', forciteRegResp.error, forciteRegResp.data.message)
          setState({...initialState, userChecked: true});
          rej(forciteRegResp.data.message);
        }
      }).catch(error => {
        // console.log('Error in Forcite Rego:', error)
        setState({...initialState, userChecked: true});
        rej(error);
      });
    });
  }

  const bcRego = (fields, customFields, forciteAuthResp) => {
    return new Promise(async (res, rej) => {
      const fieldsUpdated = { ...fields };
      // console.log('bcRego in auth provider', fieldsUpdated, customFields, forciteAuthResp);
      
      // Use a token from Forcite as the password for BC account
      const token = await generateToken(forciteAuthResp.userId);
      // console.log("Token in auth provider", token);
      fieldsUpdated.authentication.new_password = token;
      fieldsUpdated.confirm_password = token;

      // Store forcite User ID
      const customFieldsUpdated = [ ...customFields ];
      customFieldsUpdated.push({
        name: 'Forcite User ID', // Forcite BC Production field - field_26
        value: forciteAuthResp.userId,
      });

      const bcApiBody = JSON.stringify([fieldsUpdated]);

      // console.log('Prepped bcRego in auth provider', fieldsUpdated, customFieldsUpdated, forciteAuthResp, bcApiBody);
      bcApi('customers', 'POST', bcApiBody).then(({response}) => {
        // console.log('customer creation in auth provider', response);
        if (response.data) {
            const customer = response.data[0];

            const updatedCustomFields = customFieldsUpdated.map(field => {
              const currentField = field;
              currentField.customer_id = customer.id;
              return currentField;
            });

            bcApi('customers/form-field-values', 'PUT', updatedCustomFields).then(response => {
              setState({...state, isLoggedIn: true, customerId: customer.id, email: customer.email, object: customer});
              // TODO: Setup secured authentication storage
              setStorage('_loggedIn', btoa(JSON.stringify({customerId: customer.id, timestamp: Date.now(), userChecked: true, envPath})))
              
              const afterAuthAction = afterAuth(customer.id);

              if (typeof window !== 'undefined' && typeof document !== 'undefined' && !afterAuthAction) {
                const returnUrl = !document.referrer.startsWith(process.env.SITE_URL) ? process.env.SITE_URL : (document.referrer.indexOf('logout') ? process.env.SITE_URL : document.referrer);
                window.location = returnUrl;

                // bcLoginUrl can't be used as the redirect has to be relative to the BC store URL.
                // bcLoginUrl(customer.id, returnUrl).then(redirectUrl => {
                //   console.log("Signed in promise", redirectUrl);
                //   window.location = redirectUrl
                // });
              }
              res(customer);
            });
        } else {
          setState({...initialState, userChecked: true});
          const messageKeys = Object.keys(response.errors);
          let message = response.errors[messageKeys[0]].split(':');
          message = message[message.length - 1].trim();
          message = message.charAt(0).toUpperCase() + message.slice(1);
          // console.log(response.errors, message, messageKeys);
          if (message === "Error.path.missing") {
            message = "Multiple fields are empty. Please fill all available fields in.";
          }
          rej(message);
        }
      })
      .catch(error => {
        // console.log('Error:', error)
        setState(initialState)
        rej(error);
      });
    });
  }

  return (
    <AuthContext.Provider value={{
        state,
        setState,
        addSupport,
        refreshData,
        login,
        logout,
        signup,
        checkPassword,
        // forgotPassword,
        // changePassword
    }}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;